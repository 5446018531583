
import { createStore } from 'vuex'

const store = createStore({
    state () {
        return {
            head: {},
            table_data: []
        }
    },

    mutations: {
        SET_HEAD(state, head) {
            state.head = head
            // localStorage.setItem("token", token)
        },
        SET_TABLE_DATA(state, table_data){
            state.table_data = table_data
            // sessionStorage.setItem("userInfo", JSON.stringify(table_data))
        }
    }
})

export { store };
