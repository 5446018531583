import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import  {store}  from './store'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import JsonExcel from 'vue-json-excel'

import * as math from 'mathjs'



const app = createApp(App);
app.use(router);
app.use(store)
app.use(ElementPlus)
app.config.globalProperties.$math = math
app.component('downloadExcel', JsonExcel)
app.mount('#app');
