import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        name: 'calculator',
        path: '/',
        component: () => import('./calculator'),
        meta: {
            title: '贷款计算器',
        },
    },
    {
        name: 'info',
        path: '/info',
        component: () => import('./Info'),
        meta: {
            title: '详细信息',
        },
    }
];

const router = createRouter({
    routes,
    history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

export { router };